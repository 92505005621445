.btn {
    font-family: $font-roboto-medium;
    display: inline-block;
    background: transparent;
    font-size: 14px;
    font-weight: 500;
    height: 36px;
    padding: 5px 10px;
    border: none;
    -webkit-transition: all .25s ease-in-out;
    border-radius: 4px;
    transition: all .25s ease-in-out;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    opacity: 1;
    text-transform: uppercase;
    letter-spacing:0.089em;

    &--purple {
        background-color: $color-purple;
        color: #ffffff;

        .svg-icon {
            width: 15px;
            height: 15px;

            svg {
                width: 15px;
                height: 15px;
                fill: #ffffff;
            }
        }

        &__check-in {
            color: $color-yellow;
            width: 100%;
        }
        
        &__check-out {
            color: $color-green-71AC86;
            width: 100%;
        }
    }

    &--savePdf {
        background-color: #2C4285;
        color: #ffffff;
        width: 100%;
        height: 40px;
    }

    &--light-purple {
        opacity: 0.48;
        background-color: $color-purple-lighter;
        color: $color-grey-E5E5E5;
    }

    &--no-opacity {
        opacity: 1;
    }

    &--dark-purple {
        background-color: $color-purple-2C4285;
        color: #ffffff;
    }

    &--link {
        color: #ffffff;
        text-transform: none;
        text-decoration: underline;
        border: unset;
    }

    &--green {
        background-color: $color-green-71AC86;
        color: #ffffff;

        .svg-icon {
            width: 20px;
            height: 20px;

            svg {
                width: 20px;
                height: 20px;
                fill: #ffffff;
            }
        }
    }

    &--danger {
        background-color: #990000;
        color: #ffffff;
    }

    &--bigger {
        height: 40px;
    }

    &--with-icon {
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 15px;
        text-transform: uppercase;

        &--left {
            padding-left: 10px;

            .svg-icon {
                order: 1;
                margin: 0 10px 0 0;
            }
        }

        &--right {
            padding-right: 15px;

            .svg-icon {
                margin: 0 0 0 10px;
            }
        }
    }

    &--icon-center {
        display: flex;
        justify-content: center;
        .svg-icon {
            margin-left: 10px;
            margin-bottom: 10px;
            svg {
                width: 30px !important;
                height: 30px !important;
            }
        }
    }

    &--just-icon {
        padding: 0;
        align-items: center;

        .svg-icon {
            width: auto;
            height: auto;

            svg {
                width: 22px;
                height: 22px;
            }
        }
    }

    &--outlined {
        border: 1px solid $color-purple;
        color: $color-purple;
    }

    &--disabled {
        color: #FFFFFF;
        background-color: $color-grey-9F9F9F;
        cursor: default;
        opacity: 0.5;
    }

    &--full-width {
        width: 100%;
    }

    .svg-icon {
        width: 20px;
        height: 20px;

        svg {
            width: 20px;
            height: 20px;
        }
    }

    > span {
        order: 2;
    }
}

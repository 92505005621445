/**
 *
 * @Copyright 2024 VOID SOFTWARE, S.A.
 *
 */

.card {
    border-radius: 6px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: box-shadow 0.3s ease;
    width: 350px;

    &--expanded {
        height: 50%;
    }
}

.img-container {
    width: 100%;
    height: 200px;
    overflow: hidden;
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
}

.content {
    display: flex;
    flex-direction: column;
    padding: 12px;
    height: 135px;
    transition: height 0.8s ease;

    &--expanded {
        height: 175px;
    }
}

.info-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 32px;

    .title {
        font-size: 1.5em;
        margin: 0;
        color: $color-purple;
    }
        
    .icons {
        display: flex;
        gap: 10px;
        margin: 0 10px;

        img {
            width: 32px;
            height: 32px;
        }
    }
}


.description {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    padding: 0 10px;
    font-size: 1em;
    color: #666;
    margin-top: 8px;
    max-height: 3em;
    overflow: hidden;
    transition: max-height 0.4s ease;

    .details {
        display: flex;
        justify-content: space-between;

        span:first-child {
            width: 165px;
        }
    }

    .no-damage {
        color: #333;
        margin-bottom: 20px;
    }

    &--expanded {
        max-height: 100px;
        overflow-y: auto;
    }
}

.toggle-button {
    width: 100%;
    height: 32px;
    margin-top: 15px;
    cursor: pointer;
}

// colors
$color-grey-00000099: #00000099;
$color-grey-E5E5E5: #E5E5E5;
$color-grey-9F9F9F: #9F9F9F;
$color-grey-292929: #292929;
$color-grey-F5F5F5: #F5F5F5;
$color-grey-707070: #707070;
$color-grey-8B8B8B: #8B8B8B;
$color-grey-1F1F1F: #1F1F1F;
$color-grey-BABABA: #BABABA;
$color-grey-414141: #414141;
$color-grey-6E6E6E: #6E6E6E;
$color-grey-727272: #727272;
$color-grey-636363: #636363;
$color-grey-333333: #333333;

$color-black-0B0B0B: #0B0B0B;

$color-green-71AC86: #71AC86;

$color-purple: #a81ead;
$color-purple-lighter: #A8449E;
$color-purple-darker: #430F64;
$color-purple-light: #792B92;
$color-purple-47408C: #47408C;
$color-purple-2C4285: #2C4285;
$color-purple-648FC2: #648FC2;
$color-purple-B938A8: #B938A8;
$color-purple-45408A: #45408A;
$color-purple-B538A7: #B538A7;
$color-purple-50408E: #50408E;
$color-purple-B038A6: #B038A6;
$color-purple-AC39A5: #AC39A5;
$color-purple-40418A: #40418A;

$color-green: #71AC86;
$color-red: #F4001C;
$color-red-FF0049: #FF0049;
$color-yellow: #FFD800;
$color-orange: #FA8223;
$color-select-blue: #578FEB;

$color-cream-FAFAFA: #FAFAFA;
$color-white-F7F7F7: #F7F7F7;

$color-purple-darker-transparent: rgba(67, 15, 100, 0.63);
$color-text-field-bg: rgba(#F5F5F5, 0.81);

$border-gradient: linear-gradient(180deg, rgba(209, 36, 168, 0.54), rgba(11, 52, 119, 0.54));

// layout
$app-screen-topbar-height: 85px;

// fonts
$font-titillium: 'TitilliumWeb', sans-serif;
$font-titillium-semibold: 'TitilliumWebSemibold', sans-serif;
$font-roboto: 'Roboto', sans-serif;
$font-roboto-medium: 'RobotoMedium', sans-serif;
$font-titillium-bold: 'TitilliumWebBold', sans-serif;


.loader-wrapper {
    display: flex;
    position: fixed;
    z-index: 10;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, .2);

    &.contained {
        position: absolute;
    }
}

.loader {
    width: 40px;
    height: 40px;

    > span {
        display: inline-block;
        width: 40px;
        height: 40px;

        svg {
            width: 100%;
            height: 100%;
        }
    }
}

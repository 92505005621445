.app-screen__topbar {
    width: 100%;
    min-height: $app-screen-topbar-height;
    background-image: linear-gradient(270deg, rgba(209, 36, 168, 0.92), rgba(11, 52, 119, 0.92));
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__container {
        padding: 0 15px;
        height: 100%;
        min-width: 100%;
        display: inline-flex;
        justify-content: space-between;
        align-items: center;

        @media (max-width: 768px) {
            > img {
                display: none;
            }
        }

        > img {
            cursor: pointer;
        }

        &__menu {
            display: inline-flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            padding: 20px;
            color: #ffffff;

            @media (max-width: 768px) {
                padding: 20px 10px 20px 0;
            }

            .message {
                position: relative;

                .number-div {
                    position: absolute;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    top: 0;
                    right: 0;
                    width: 20px;
                    height: 20px;
                    font-size: 10px;
                    font-family: $font-titillium-semibold;
                    border-radius: 10px;
                    background-color: $color-red-FF0049;
                    border: 2px solid #ffffff;
                    box-shadow: 0px 3px 6px #00000029;
                    margin-right: 45px;
                    margin-top: -10px;
                }

                .menu-div {
                    position: relative;

                }
            }

            &__menu-item {
                height: 100%;

                a {
                    color: #FFFFFF;
                    height: 100%;
                    margin: 0 31px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    opacity: 0.5;
                    text-decoration: none;

                    svg {
                        width: 20px;
                        height: 20px;
                        fill: #FFFFFF;
                    }

                    &.active {
                        opacity: 1;
                    }

                    &:hover {
                        &.active {
                            opacity: 1;
                        }
                        opacity: 0.8;
                    }

                }

                a:hover, a:focus {
                    text-decoration: none;
                    color: inherit;
                }
            }
        }

        &__right-container {
            display: inline-flex;
            align-items: center;

            &__logout {
                text-align: right;
                margin-right: 21px;

                .user-name {
                    font-family: $font-titillium-bold;
                    font-size: 14px;
                    line-height: 9px;
                    color: #ffffff;
                    cursor: pointer;

                    @media(max-width: 768px) {
                        line-height: 14px;
                    }
                }

                button {
                    padding: 0;
                    font-size: 12px;
                    font-family: $font-titillium;
                    line-height: 17px;
                    height: unset;
                    opacity: 0.70;
                }
            }
        }
    }

    @media (max-width: 768px) {
        &__container {
            padding: 0 5px;

            > img {
                cursor: pointer;
                max-width: 100px;
            }

            &__menu {
                justify-content: space-around;
                width: 100%;

                &__menu-item {
                    min-width: 0px;
                    height: 100%;
                    padding-left: 20px;

                    a {
                        margin: 0 2px;

                        .svg-icon {
                            svg {
                                width: 20px;
                                height: 20px;
                                fill: #FFFFFF;
                            }
                        }
                    }

                }

                .message {
                    position: relative;
                        .number-div {
                            position: absolute;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            top: 0;
                            right: 0;
                            width: 20px;
                            height: 20px;
                            font-size: 10px;
                            font-family: $font-titillium-semibold;
                            border-radius: 10px;
                            background-color: $color-red-FF0049;
                            border: 2px solid #ffffff;
                            box-shadow: 0px 3px 6px #00000029;
                            margin-right: 0px;
                            margin-top: -10px;
                        }
                }
            }
        }
    }
}

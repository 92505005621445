/**
 *
 * @Copyright 2024 VOID SOFTWARE, S.A.
 *
 */

.draw-image {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    display: inline-flex;
    justify-content: center;
    flex-direction: column;
    background-color: rgba($color-grey-1F1F1F, 0.85);
    padding: 10px;
    gap: 10px;
    backdrop-filter: blur(5px);

    &__inner {
        display: flex;
        width: 100%;
        height: 100%;
        position: relative;

        canvas {
            display: block;
            width: 65%;
            height: 100%;
            z-index: 1;
            left: 17.5%;
            position: absolute;
            object-fit: cover;
            border-radius: 6px;
        }

        .btn-close {
            position: absolute;
            top: 10px;
            right: 10px;
            background-color: rgba($color-white-F7F7F7, 0.5);
            border-radius: 4px;
            width: 26px;
            height: 26px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            z-index: 1001;

            img {
                width: 16px;
                height: 16px;
            }
        }

        .comments-boxes {
            position: absolute;
            top: 0;
            left: 17.5%;
        }

        .box-wrapper {
            position: absolute;
            display: flex;
            flex-direction: column;
            align-items: center;
            z-index: 100;

            &__box {
                width: 100%;
                height: 100%;
                border-style: solid;
                border-color: green;
            }

            &__close-btn {
                position: absolute;
                top: -7px;
                right: -7px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                height: 20px;
                width: 20px;
                background-color: #000;
                border-radius: 50%;
                fill: #FFF;
                opacity: 0.7;

                svg {
                    width: 12px;
                    height: 12px;
                }

                &.zoomed {
                    width: 30px;
                    height: 30px;

                    svg {
                        width: 17px;
                        height: 17px;
                    }
                }
            }

            &__comment {
                position: absolute;
                bottom: -27px;
                left: 0;
                right: 0;
                text-align: center;

                > div {
                    display: inline-block;
                    padding: 2px 4px;
                    background-color: rgba($color-purple-lighter, 0.7);
                    max-width: 100%;
                    font-size: 13px;
                    color: $color-black-0B0B0B;

                    p {
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        color: #FFF;
                        min-width: 15px;
                        min-height: 13px;
                    }
                }
            }
        }
    }

    &__details {
        width: 100%;
        min-width: 165px;
        height: 10vh;
        min-height: 110px;
        z-index: 100;
        display: flex;
        justify-content: center;

        &__inner {
            display: inline-flex;
            align-items: center;
            justify-content: space-between;
            gap: 20px;
            background-color: #FAFAFA;
            border-radius: 8px;
            height: 100%;
            padding: 8px 15px;
            min-width: 515px;

            .damage-opacity-input {
                height: 100%;
            }

            &__actions {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-template-rows: 1fr 1fr;
                gap: 8px;
                min-width: 210px;

                p {
                    font-family: $font-titillium-semibold;
                    grid-column: 1 / 3;
                    grid-row: 1 / 3;
                    font-size: 14px;
                }

                .btn-edit-damages {
                    grid-column: 1 / 3;
                    grid-row: 1 / 3;
                }

                &__bigger-btn {
                    grid-column: 1 / 3;
                }
            }
        }
    }

    .image-loader {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        z-index: 100;
        width: 100%;
    }
}

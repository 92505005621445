.budget-details {
    display: flex;
    flex-direction: column;
    width: 100%;

    &_header {
        background: $color-grey-F5F5F5;
        padding-top: 40px;
        padding-right: 40px;
        padding-left: 40px;
        margin-bottom: 40px;
        min-width: 500px;

        &_row {
            display: flex;
            justify-content: space-between;
            margin-bottom: 40px;

            &_left {
                display: flex;
                flex-direction: row;
                flex-grow: 1;
    
                &_element {
                    margin-right: 40px;
    
                    .label {
                        font-family: $font-roboto;
                        font-size: 13px;
                        color: $color-grey-9F9F9F;
                    }
    
                    .value {
                        font-family: $font-titillium;
                        font-size: 16px;
                        color: $color-grey-00000099;
                    }
                }
            }

            &_right {
                &_element {
                    .label {
                        font-family: $font-titillium-bold;
                        font-size: 13px;
                        color: $color-purple-B038A6;
                    }
    
                    .value {
                        font-family: $font-titillium-semibold;
                        font-size: 20px;
                        color: $color-grey-00000099;
                    }
                }
            }
        }
    }

    &_body {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        

        &_left {
            flex-grow: 1;
            margin-right: 40px;
        }

        &_right {
            min-width: 50%;
            margin-top: 20px;

            .form-element {
                display: flex;
                justify-content: space-between;
                margin-bottom: 20px;
            }

            .item {
                width: 50%;

                &.m-r {
                    margin-right: 8px;
                }
                &.m-l {
                    margin-left: 8px;
                }

                .radio-group {
                   display: flex;
                }
            }
        }

        table {
            width: 100%;

            thead {
                tr {
                    height: 40px;
                    th {
                        font-family: $font-titillium-semibold;
                        font-size: 13px;
                        color: $color-purple-B038A6;
                        padding-right: 16px; 
                    }
                }  
            }

            tbody {
                tr {
                    height: 40px;
                    td {
                        font-family: $font-titillium;
                        font-size: 14px;
                        color: $color-grey-00000099;
                    }

                    td:nth-child(1) {
                        padding-left: 8px;
                    }

                    td.right {
                        text-align: end;
                        padding-right: 16px;
                    }

                    td.padding-r-10-cell {
                        padding-right: 10px;
                    }  
                }

                tr:nth-child(2n+1) {
                    background-color: $color-grey-F5F5F5;
                }

                tr.m-bigger {
                    height: 60px;
                }

                tr.c-dark {
                    background: $color-grey-E5E5E5;
                    td {
                        font-family: $font-titillium-semibold;
                    }  
                }
            }
        }
    }
    
}
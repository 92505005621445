/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

body.react-confirm-alert-body-element {
    overflow: unset;
}

.react-confirm-alert-overlay {
    background: rgba(0, 0, 0, .5) !important;
    z-index: 3000;
}

.confirm {
    &__inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 25px;
        color: $color-grey-414141;
        background-color: #FFFFFF;
        font-family: $font-titillium;
        border-radius: 5px;
        max-width: 600px;

        &.options-select {
            min-width: 310px;
        }

        &.confirmation {
            max-width: 400px;

            &.remove-document {
                max-width: 650px;
            }
        }

        &.token {
            max-width: 600px;

            button {
                margin-top: 10px;
            }
        }

        > .svg-icon {
            width: 50px;
            height: 50px;
            margin-bottom: 20px;

            svg {
                width: 50px;
                height: 50px;

                path {
                    fill: $color-purple;
                }
            }
        }

        > h6 {
            font-family: $font-titillium-semibold;
            font-size: 20px;
            text-align: center;
        }

        > p {
            font-size: 18px;
            text-align: center;
        }

        button {
            margin-top: 20px;
        }

        &__buttons {
            display: flex;
            width: 100%;
            justify-content: flex-end;
            margin-top: 20px;
            margin-bottom: 2px;

            &.full-width {
                justify-content: center;
            }

            button {
                &:first-of-type {
                    margin-right: 10px;
                }
            }
        }
    }

}

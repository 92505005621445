.app-screen__form-edit-user {
    width: 100%;

    &__top-container {
        width: 100%;
        padding: 15px;
        display: flex;
        justify-content: space-between;

        @media(max-width: 768px) {
            grid-row-gap: 20px;
            flex-flow: column;
        }

        .buttons-container {
            display: inline-flex;

            @media(max-width: 768px) {
                justify-content: center;
            }

            .btn:first-of-type {
                margin-right: 10px;
            }
        }

        .info-container {
            display: inline-flex;

            .title {
                font-size: 14px;
                font-family: $font-titillium;
            }

            .user-name {
                font-size: 18px;
                font-family: $font-titillium-bold;
            }

            .svg-icon {
                margin-top: 10px;
                margin-right: 20px;

                svg {
                    width: 15px;
                    height: 15px;
                }
            }

            .status-dot {
                margin-top: 5px;
            }
        }
    }

    &__container {
        display: flex;
        justify-content: center;
        margin-bottom: 30px;

        &__content {
            width: 75%;
            font-family: $font-titillium;

            h3 {
                font-size: 18px;
                font-family: $font-titillium-semibold;
                color: rgba($color-purple-47408C, 0.59);
            }

            .subtitle-with-margin {
                margin-top: 30px;
            }

            .row {
                margin-top: 10px;
                display: flex;
                align-items: flex-end;

                .btn-column {
                    align-self: flex-end;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                }

                .dispute-with-link {
                    padding-left: 20px;
                    > span {
                        margin-left: 2px;
                        color: $color-purple-2C4285;
                        font-family: $font-titillium-bold;
                        cursor: pointer;
                        font-size: 15px;
                    }
                }
            }
        }
    }
}

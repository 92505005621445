.statistics {
    padding: 40px 300px;

    @media(max-width: 768px) {
        padding: 20px;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-content: center;
        margin-bottom: 40px;
        gap: 10px;
        
        @media(max-width: 660px) {
            flex-flow: column;
            width: 100%;
        }

        &__filter {
            display: flex;
            flex-direction: column;
            width: 100%;

            @media(max-width: 660px) {
                margin-bottom: 20px;
            }

            .field-container {
                height: unset;
                padding: 10px;
                margin-top: 8px;
                min-width: 200px;

                .searchable-select__single-value {
                    color: $color-grey-333333;
                }
            }
        }
    }

    &__container {
        background: $color-grey-F5F5F5;
        padding: 60px;
        min-width: 600px;
        margin-bottom: 16px;

        @media(max-width: 768px) {
            width: 100%;
            min-width: unset;
            padding: 10%;
        }

        &__info {
            display: flex;
            font-family: $font-titillium-semibold;
            margin-bottom: 40px;

            &__header {
                display: flex;
                width: 100%;

                @media(max-width: 660px) {
                    flex-flow: column;
                }

                &__title {
                    min-width: 30%;
                }

                &__body {
                    display: flex;

                    @media(max-width: 660px) {
                        justify-content: space-around;
                        width: 100%;
                    }
                }
            }

            .text {
                margin-right: 40px;

                @media(max-width: 660px) {
                    margin-right: 0;
                }

                &-dark {
                    
                    color: #000;
                    opacity: .59; 
                }

                &-big {
                    font-size: 16px;
                }

                &-column {
                    display: flex;
                    flex-direction: column;
                }

                &-purple {
                    color: $color-purple-AC39A5;
                }

                &-small {
                    font-family: $font-titillium;
                    font-size: 11px;
                }

                &-blue {
                    color: $color-purple-40418A;
                }

                &-grey {
                    color: $color-grey-727272;
                }
            }
        }

        &__chart {
            display: flex;
            align-content: center;
            justify-content: center;
            font-family: $font-titillium;
            font-size: 12px;
            color: $color-grey-707070;

            canvas {
                max-width: 800px;
            }
        }
    }
}
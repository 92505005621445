.app-screen {
    height: 100%;
    width: 100%;
    position: relative;

    .app-router {
        height: 100%;
        width: 100%;
    }

    .index-screen {
        height: 100%;
        width: 100%;

        .incoming-call-container {
            position: fixed;
            bottom: 20px;
            right: -300px;
            z-index: 5;
            background-color: $color-grey-1F1F1F;
            padding: 10px;
            color: #FFFFFF;
            display: inline-flex;
            -webkit-animation: slide 0.5s forwards;
            -webkit-animation-delay: 2s;
            animation: slide 0.5s forwards;
            animation-delay: 2s;
            box-shadow: 0 1px 3px #00000033;
            align-items: center;

            .call-info {
                text-align: right;
                font-size: 12px;

                > h3 {
                    font-family: $font-titillium-bold;
                    line-height: 15px;
                    text-transform: uppercase;
                }

                > p {
                    font-family: $font-titillium;

                    &.small {
                        font-size: 10px;
                    }
                }
            }

            .icon-container {
                height: 32px;
                width: 32px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                padding: 7px;
                background-color: $color-green-71AC86;
                margin-left: 10px;
                cursor: pointer;

                .svg-icon {
                    height: 18px;
                    width: 18px;

                    svg {
                        height: 18px;
                        width: 18px;
                    }
                }
            }

            @-webkit-keyframes slide {
                100% { right: 20px; }
            }

            @keyframes slide {
                100% { right: 20px; }
            }
        }

        .content-area {
            height: calc(100% - 70px);
        }

    }

}

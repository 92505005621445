.app-screen__modal-zoom {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 4;
    background-color: rgba($color-grey-1F1F1F, 0.85);
    top: 0;
    left: 0;

    .image-container {
        width: 100%;
        height: 100%;
        overflow: auto;

        .image-zoom1 {
            cursor: -webkit-zoom-out;
            cursor: zoom-out;
            cursor: -moz-zoom-out;
            display: block;
            margin: 0 auto;
            margin-top: 40px;
            transform-origin: 0 0;
            transform: scale(1);
            transition: ease-in-out 0s;
            -webkit-transform: scale(1); /* Safari And Chrome */
            -webkit-transform-origin: 0 0;
        }

        span {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 10;
            margin-right: 10px;
            margin-top: 5px;

            svg {
                fill: #FFF;
            }
        }
    }
}

.app-screen__modal {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 4;
    background-color: rgba($color-grey-1F1F1F, 0.85);
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;

    &__incoming-call {
        background-image: linear-gradient(90deg, rgb(11, 52, 119), rgb(209, 36, 168));
        padding-top: 5px;
        width: 400px;
        height: 200px;
        margin-top: 5%;
    }

    &__container {
        width: 490px;
        margin-top: 5%;
        background-image: linear-gradient(90deg, rgb(11, 52, 119), rgb(209, 36, 168));
        height: 552px;
        padding-top: 5px;

        &.filter-date {
            max-width: 600px;
            max-height: 400px;

            .filter-element {
                margin-bottom: 15px;
                margin-top:10px;

                &.bigger {
                    margin-top: 50px;
                    button {
                        background-color: $color-purple;
                    }
                }

                .svg-icon {
                    margin-bottom: 12px;

                    svg {
                        width: 18px;
                        height: 18px;
                    }
                }
            }
        }

        &.general-modal {
            max-width: 600px;
            max-height: 700px;

            &.new-token-modal {
                padding-top: 0;
                height: 500px;
            }
        }

        &.document-edit-container {
            margin-top: 15%;
            width: 400px;
            height: 250px;
        }

        &.new-message-modal {
            width: 630px;
            min-height: 540px;
            color: $color-grey-00000099;

            .border-header {
                padding: 10px 20px 15px 20px;
                display: flex;
                justify-content: space-between;
                width: 100%;
                align-items: center;
                color: #ffffff;
                font-family: $font-titillium-bold;
                font-size: 14px;
                line-height: 9px;

                img {
                    height: 18px;
                }
            }

            button {
                margin-top: 10px;
                align-self: flex-end;
            }
        }

        &.reassess-modal {
            width: 400px;
            max-height: 280px;

            .border-header {
                padding: 10px 20px 15px 20px;

                img {
                    height: 18px;
                }
            }

            .reassess-result-container {
                width: 300px;

                &-title {
                    font-family: $font-titillium-bold;
                    text-align: center;
                    padding: 16px;
                    font-size: 24px;
                }

                &-previous {
                    padding: 8px;
                    font-size: 16px;
                    font-family: $font-roboto-medium;
                    color: $color-grey-292929;
                }

                &-current {
                    padding: 8px;
                    font-size: 16px;
                    margin-bottom: 16px;
                    font-family: $font-roboto-medium;
                    color: $color-purple-B038A6;
                }
            }

            button {
                width: 140px;
            }
        }

        &.send-message-modal {
            width: 630px;
            max-height: 350px;

            color: $color-grey-00000099;

            .border-header {
                padding: 10px 20px 15px 20px;
                display: flex;
                justify-content: space-between;
                width: 100%;
                align-items: center;
                color: #ffffff;
                font-family: $font-titillium-bold;
                font-size: 14px;
                line-height: 9px;

                img {
                    height: 18px;
                }
            }
        }

        &.control-in-series-modal {
            width: 650px;
            max-height: 540px;
            color: $color-grey-00000099;

            .border-header {
                padding: 10px 20px 15px 20px;
                display: flex;
                justify-content: space-between;
                width: 100%;
                align-items: center;
                color: #ffffff;
                font-family: $font-titillium-bold;
                font-size: 14px;
                line-height: 9px;

                img {
                    height: 18px;
                }
            }

            button {
                margin-top: 10px;
                align-self: flex-end;
            }
        }

        &__box {
            display: flex;
            flex-direction: column;
            background-color: #FFFFFF;
            width: 100%;
            padding: 18px 18px;

            &.new-token-modal {
                box-shadow: 0px 3px 11px #00000064;
                background-image: linear-gradient(180deg, rgba(#D124A8, 0.47), rgba(#0B3477, 0.47));
                background-color: #414141;

                .svg-icon {
                    svg {
                        fill: #FFFFFF;
                    }
                }
            }

            &.incoming-call {
                display: flex;
                flex-direction: column;
                justify-content: center;
                text-align: center;
                background-color: $color-grey-1F1F1F;
                padding: 10px;
                flex: 0 0 150px;
                color: #FFFFFF;
            }

            &.document-edit-box {
                justify-content: center;

                button {
                    align-self: flex-end;
                }
            }

            &.new-message-box {
                min-height: 100%;
            }

            &.new-message-box {
                min-height: 100%;
            }

            .text-bold {
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                font-weight: 700;
                font-size: 14px;
            }

            .text-small {
                text-align: center;
                font-size: 12px;
                margin-top: 16px;
            }

            &__content {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                padding: 18px 96px 40px 96px;
                height: 95%;
                width: 100%;
                gap : 10px;

                > button {
                    margin-top: 32px;
                }

                &.incoming-call {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    .call-info {
                        padding: 20px 0;
                        height: 100%;

                        > h3 {
                            font-family: $font-titillium-semibold;
                            text-transform: uppercase;
                            font-size: 15px;
                            margin-bottom: 5px;
                        }

                        > p {
                            font-family: $font-titillium;
                            font-size: 15px;

                            &.small {
                                font-size: 12px;
                            }
                        }
                    }

                    .icon-container {
                        height: 32px;
                        width: 32px;
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        padding: 7px;
                        background-color: $color-green-71AC86;
                        margin-left: 10px;
                        cursor: pointer;

                        .svg-icon {
                            height: 18px;
                            width: 18px;

                            svg {
                                height: 18px;
                                width: 18px;
                            }
                        }
                    }
                }

                &.image-show {
                    padding: 20px 0 0 0;
                    max-height: calc(100% - 40px);

                    img {
                        max-height: 100%;
                        object-fit: contain;
                    }
                }

                &.new-token {
                    font-family: $font-titillium-semibold;
                    font-size: 14px;
                    line-height: 21px;
                    align-items: flex-start;
                    justify-content: space-between;
                    padding: 0 35px 40px 35px;
                    color: #FFFFFF;

                    > div {
                        > .regular {
                            margin-top: 10px;
                            font-family: $font-titillium;
                            line-height: 19px;
                        }

                        > .big {
                            font-family: $font-titillium-bold;
                            font-size: 18px;
                            line-height: 19px;
                        }
                    }

                    .body {
                        width: 100%;
                        height: 110px;
                        display: flex;
                        justify-content: space-between;
                        margin-top: 10px;

                        img {
                            width: 30px;
                            height: 30px;
                            margin-top: 30px;
                            cursor: pointer;
                        }
                    }

                    .action {
                        width: 100%;
                        margin-top: 40px;
                    }

                    .new-token-input {
                        width: 100%;
                        color: $color-grey-00000099;
                        margin-right: 20px;
                    }

                    .new-token-label{
                        color: $color-white-F7F7F7;
                        font-size: 15px;
                    }

                    .share-button {
                        width: 100%;
                        background-color: $color-green;
                    }
                }

                &.document-edit-box-content {
                    padding: 0 10px;
                    justify-content: center;

                    > .field-container input {
                        font-family: $font-titillium-semibold;
                    }

                    button {
                        margin-top: 20px;
                        align-self: flex-end;
                    }
                }

                &.send-report-content {
                    width: 100%;
                    justify-content: unset;

                    >div {
                        margin-bottom: 20px;
                    }

                    .btn-container {
                        margin-top: 20px;
                        display: flex;
                        width: 100%;
                        justify-content: space-between;

                        button {
                            width: 195px;
                            font-size: 10px;
                            height: 50px;
                        }
                    }
                }

                &.new-message-content {
                    padding: 0;
                    align-items: flex-start;

                    p {
                        margin-top: 5px;
                        color: rgba(#000000, 0.59);
                        font-size: 14px;
                        font-family: $font-titillium;
                    }

                    h4 {
                        color: rgba(#000000, 0.59);
                        font-size: 18px;
                        font-family: $font-titillium-bold;
                    }
                }

                &.control-in-series-content {
                    padding: 0 60px;
                    align-items: flex-start;

                    .btn--just-icon {
                        padding: 20px 10px;
                        margin-top: 20px;
                        height: 56px;
                        width: 56px;

                        svg {
                            height: 20px;
                            width: 20px;
                        }
                    }

                    h3 {
                        margin-top: 30px;
                        color: rgba(71, 64, 140, 0.59);
                        font-size: 14px;
                    }
                }

                &__header {
                    display: flex;
                    flex-direction: column;
                    color: $color-purple-light;
                    font-family: $font-titillium-semibold;
                    font-size: 14px;
                    line-height: 17px;

                    img {
                        margin-bottom: 5px;
                    }
                }

                > .image-container {
                    min-height: 300px;
                    min-width: 300px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    overflow: auto;
                    transition: ease-in-out 0s;
                    cursor: -webkit-zoom-in;
                    cursor: zoom-in;
                    cursor: -moz-zoom-in;

                    img {
                        margin: auto;
                        padding: 10px;
                        width: 100%;
                    }
                }

                > .linear {
                    margin: 20px 0;
                    width: 100%;
                    display: inline-flex;

                    > .field-new-message {
                        width: 50%;
                        color: $color-grey-00000099;

                        .disabled {
                            background-color: rgba($color-grey-F5F5F5, 0.81);
                        }

                        &:first-of-type {
                            margin-right: 5px;
                        }

                        &:last-of-type {
                            margin-left: 5px;
                        }
                    }

                    > .linear__align-btn-right {
                        display: flex;
                        justify-content: flex-end;
                    }
                }

                > .inline {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;

                    > div:nth-child(2) {
                        margin-left: 8px;
                    }
                }

                > .mt-10 {
                    @extend .linear;
                    margin-top: 10px;
                    margin-bottom: 0;
                }

                .field-new-note {
                    width: 50%;
                }

                .field-new-message {
                    width: 100%;

                    .disabled {
                        background-color: rgba($color-grey-F5F5F5, 0.81);
                    }
                }

                .field-message-text {
                    margin-top: 20px;
                    color: $color-grey-00000099;
                    width: 100%;
                    flex: 1 1 auto;

                    .disabled {
                        background-color: rgba($color-grey-F5F5F5, 0.81);
                    }
                }

                .field-container {
                    width: 100%;

                    select, input {
                        font-family: $font-titillium;
                        font-size: 13px;
                        line-height: 20px;
                        letter-spacing: 0.12px;
                    }

                    select {
                        height: 52px;
                        appearance: none;
                        background: url('../../../images/icon-chevron_down-select.svg') right center no-repeat;
                    }
                }
            }

            .svg-icon {
                align-self: flex-end;

                svg {
                    width: 10px;
                    height: 10px;
                }
            }
        }
    }
}

.draw-image-container {
    font-family: $font-titillium-semibold;
    position: relative;
    max-height: 95%;
    cursor: zoom-in;

    &__zoomed {
        width: 100%;
        height: 100%;
        overflow: auto;
        margin: 0;
        cursor: pointer;

        img {
            display: block;
            transform-origin: 0 0;
            transform: scale(1);
        }

        &--button-group {
            margin-top: 10px;
            margin-right: 30px;
            padding-top: 5px;
            position: fixed;
            top: 0;
            right:0;
            z-index: 210;
            display: flex;
            column-gap: 10px;

            span {
                background-color: $color-grey-1F1F1F;
                border-radius: 50%;
                width: 30px;
                height: 30px;
                display: grid;
                place-items: center;
                fill: #fff;
                opacity: 0.9;
            }
        }

        .draw-message {
            position: fixed;
            top: 0;
            left: 0;
            margin-top: 10px;
            margin-left: 10px;
            border-radius: 5px;
            z-index: 200;
            background-color: $color-grey-1F1F1F;
            color: white;
            text-transform: uppercase;
            font-size: 16px;
            padding: 10px;
            opacity: 0.9;
        }

        .draw-image-container--close-button {
            width: 30px;
            height: 30px;
            padding-top: 5px;
        }
    }

    &--close-button {
        position: absolute;
        background-color: #000;
        z-index: 2;
        border-radius: 50%;
        fill: #fff;
        width: 20px;
        overflow: visible;
        opacity: 0.7;
        text-align: center;
    }

    &--note {
        position: absolute;
        opacity: 0.7;
        color: white;
        text-align: center;
        z-index: 2;

        p {
            background-color: $color-purple-lighter;
            word-break: break-all;
        }

        input {
            color: #000;
        }

    }

    canvas {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
    }

    .unzoomed {
        width: 100%;
    }
}

/**
 *
 * @Copyright 2024 VOID SOFTWARE, S.A.
 *
 */

.damage-tool-screen {
    width: 100%;
    height: 90%;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .dropzone-wrapper {
        width: 100%;
        height: 85vh;
        border: 1px solid rgba(#000, 0.3);
        background-color: rgba(#000, 0.05);
        border-radius: 16px;
        cursor: pointer;

        > div {
            width: 100%;
            height: 100%;
            display: inline-flex;
            align-items: center;
            justify-content: center;

            p {
                font-size: 18px;
                font-family: $font-titillium-semibold;
            }
        }
    }

    &__img-wrapper {
        display: flex;
        justify-content: center;
        width: 90vw;
        position: relative;
        border-radius: 10px;

        img {
            width: 72%;
            border-radius: 8px;
            object-fit: contain;
        }

        canvas {
            position: absolute;
            top: 0;
            left: 14%;
        }
    }

    &__actions {
        width: 65vw;;
        display: inline-flex;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 15px;
        gap: 15px;

        .opacity-value {
            margin-bottom: 10px;
        }
    }
}

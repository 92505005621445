.app-screen__form {
    display: flex;
    width: 100%;
    height: calc(100vh - #{$app-screen-topbar-height});
    flex: 1 1 auto;

    @media (max-width: 768px) {
        flex-flow: column;
        height: unset;
    }

    &.hidden-overflow {
        overflow-y: hidden;
    }

    &.pre-inspection-form {
        .row {
            padding: 0 10px;
        }

        @media(max-width: 768px) {
            width: 100vw;

            .row {
                .row,
                .col-sm-3,
                .col-sm-4,
                .col-sm-6,
                .col-sm-8 {
                    padding-right: 0 !important;
                    padding-left: 0 !important;
                }
            }
        }
    }

    &__top-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    &__form-container {
        font-family: $font-titillium-semibold;
        font-size: 13px;
        text-align: left;
        color: $color-grey-00000099;
        width: calc(100% - 260px);

        .radio-field {
            margin-top: 10px;
            justify-content: center;
            align-items: center;

            span {
                padding-left: 10px;
            }
        }

        .margin-right-20 {
            margin-right: 20px;
        }

        button {
            &.selected {
                color: $color-grey-9F9F9F;
            }
        }

        .button-container {
            display: flex;
            justify-content: flex-end;
            width: 100%;

            button {
                margin-left: 5px;
            }
        }

        &__no-content {
            width: 100%;
            margin-top: 150px;
            display: flex;
            flex-direction: column;
            justify-items: center;
            align-items: center;
            font-family: $font-titillium;

            &.grid {
                margin-top: 50px;
            }

            .content {
                > .svg-icon {
                    width: 224px;
                    height: 160px;
                    svg {
                        width: 224px;
                        height: 160px;
                        fill-opacity: 0.1;
                    }
                }

                > h1 {
                    margin-top: 25px;
                    font-size: 52px;
                    text-align: left;
                    line-height: 65px;
                    color: rgba($color-purple-B938A8, 0.63);
                }

                > p {
                    margin: 10px 0 20px 0;
                    color: rgba($color-purple-2C4285, 0.63);
                    font-size: 16px;
                    line-height: 15px;
                }
            }

            .btn {
                box-shadow: 0 1px 3px #00000033;
            }

            .buttons {
                > button {
                    display: block;
                    min-width: 260px;

                    &:last-of-type {
                        margin-top: 10px;
                    }
                }
            }
        }

        &.without-tabs {
            padding: 30px 50px;

            &.pre-inspection-form {
                padding: 30px 35px;

                @media(max-width: 768px) {
                    width: 100vw;
                    padding: 10px 0;
                }

                .glass-top-container {
                    margin: 10px 15px;
                    background-image: linear-gradient(270deg, rgba(#D124A8, 0.92) 0%, rgba(#0B3477, 0.92) 100%);
                    border-radius: 5px;
                    padding: 16px 40px;
                    color: #FFFFFF;
                    font-family: $font-titillium;
                    display: inline-flex;
                    align-items: center;
                    justify-content: space-between;
                    width: calc(100% - 30px);

                    .logo-container {
                        width: 160px;
                        flex-grow: 1;
                        margin-left: 40px;

                        .logo {
                            display: inline-flex;
                            align-items: center;

                            > p {
                                margin-left: 4px;
                                padding-top: 20px;
                                font-family: $font-titillium-semibold;
                                font-size: 13px;
                                letter-spacing: 0.12px;
                                line-height: 15px;
                                color: #D124A8;
                            }
                        }

                        svg {
                            width: 100%;
                        }

                        button {
                            margin-top: 27px;
                        }
                    }

                    .state-container {
                        display: flex;
                        flex-direction: column;

                        > .top {
                            display: inline-flex;
                            font-size: 13px;
                            letter-spacing: 0.12px;
                            line-height: 20px;
                            text-transform: uppercase;
                            font-family: $font-titillium-semibold;

                            .column {
                                text-align: center;
                                display: flex;
                                flex-direction: column;
                                justify-content: space-between;

                                &:nth-child(2) {
                                    margin: 0 60px;
                                }

                                .title {
                                    text-transform: none;
                                    color: rgba(#FFFFFF, 0.58);
                                    margin-bottom: 3px;
                                    font-family: $font-titillium;
                                }

                                .small {
                                    font-size: 10px;
                                    letter-spacing: 0.1px;
                                    line-height: 15px;
                                    font-family: $font-titillium;
                                    color: $color-white-F7F7F7;
                                    text-transform: none;
                                }

                                .select-container {
                                    font-family: $font-titillium-semibold;
                                    font-size: 13px;
                                    letter-spacing: 0.12px;
                                    line-height: 20px;
                                    height: 25px;
                                    width: 130px;
                                }

                                .select-field {
                                    background-color: transparent;
                                    text-transform: uppercase;
                                    height: 25px;
                                    border-radius: 0;
                                    padding: 0;

                                    select {
                                        text-transform: uppercase;
                                        border-bottom: 1px solid white;

                                        option {
                                            color: $color-grey-00000099;
                                        }
                                    }
                                }
                            }
                        }

                        > .middle {
                            display: inline-flex;
                            justify-content: space-between;
                            align-items: center;
                            width: 100%;
                            position: relative;
                            margin: 20px 0;

                            .pictures-container {
                                width: 100%;
                                display: inline-flex;
                                align-items: center;
                                justify-content: space-between;
                                z-index: 1;

                                .svg-icon {
                                    svg {
                                        width: 51px;
                                        height: 51px;
                                    }
                                }

                                > .images {
                                    display: inline-flex;

                                    img {
                                        margin: 0 2px;
                                        width: 53px;
                                        height: 53px;
                                        border-radius: 50%;
                                        cursor: pointer;
                                    }
                                }
                            }

                            .dashed {
                                width: 100%;
                                position: absolute;
                                border: 2px dashed #FFFFFF;
                                opacity: 0.5;
                                left: 0;
                            }
                        }

                        > .bottom {
                            display: inline-flex;
                            justify-content: flex-end;

                            .text {
                                text-align: right;
                                font-family: $font-titillium;
                                font-size: 12px;
                                letter-spacing: 0.11px;
                                line-height: 15px;
                                margin-right: 20px;
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                margin-left: 25px;

                                .bold {
                                    font-family: $font-titillium-bold;
                                    font-size: 14px;
                                    letter-spacing: 0.13px;
                                    line-height: 15px;
                                }
                            }

                            .text-field-container {
                                width: 130px;

                                P {
                                    margin-top: 2px;
                                    border-radius: 2px;
                                    text-align: center;
                                    background-color: rgba(#FFFFFF, 0.3);
                                }
                            }

                            .text-field {
                                background-color: #FFFFFF;
                                color: $color-grey-00000099;
                                text-align: center;

                                input {
                                    text-align: center;
                                }
                            }
                        }
                    }
                }

                .row {
                    margin-bottom: 10px;

                    .col-sm-4, .col-sm-6, .col-sm-8, .col-sm-2, .col-sm-12 {
                        padding: 0;
                    }

                    .p-r-10 {
                        padding-right: 15px;
                    }

                    .p-l-10 {
                        padding-left: 15px;
                    }
                }

                .info-area {
                    margin: 10px 15px;
                    padding: 13px 20px;
                    border-radius: 4px;
                    background-color: rgba($color-grey-F5F5F5, 0.81);

                    &--scrollable {
                        overflow-x: auto;
                        overflow-y: hidden;
                        padding: 0;
                        margin: 0;
                    }

                    &.without-side-margins {
                        margin: 10px 0;
                    }

                    > .title {
                        color: $color-purple-B538A7;
                        font-family: $font-titillium-semibold;
                        font-size: 16px;
                        line-height: 19px;
                    }

                    > .info-container {
                        &::before {
                            display: none;
                        }
                    }

                    > .info-container {
                        display: inline-flex;
                        width: 100%;
                        margin-top: 10px;
                        font-family: $font-titillium-semibold;
                        color: $color-grey-00000099;
                        font-size: 13px;
                        line-height: 20px;
                        letter-spacing: 0.12px;

                        &.image-row {
                            height: 100%;
                            gap: 40px;

                            svg {
                                width: 18px;
                                height: 18px;
                            }
                        }

                        > .image-container {
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            align-items: center;
                            width: 350px;
                            gap: 10px;
                            background-color: rgb(222, 222, 222);
                            border-radius: 6px;

                            &--open {
                                height: 50%;
                            }

                            .image-wrapper {		                            
                                img {
                                    cursor: pointer;
                                    width: 300px;
                                    height: 170px;
                                    object-fit: fill;
                                    border-radius: 6px;
                                }		
                            }
                        }

                        .first-item {
                            flex-grow: 1;

                            &__inline-input {
                                width: 75%;
                                font-family: $font-titillium-semibold;
                                font-size: 13px;
                                letter-spacing: 0.12px;
                                line-height: 20px;
                                color: #00000099;
                                background-color: transparent;
                            }
                        }

                        .subtitle {
                            color: $color-purple-50408E;
                            font-family: $font-titillium;
                        }
                    }
                }
            }
        }

        &.create-edit-form {
            padding-top: 16px !important;
        }

        &.form-content {
            width: 100%;
            padding: 42px;
            overflow-x: hidden;

            @media(max-width: 768px) {
                width: 100vw;
                padding: 10px;

                .row {
                    .col-sm-3,
                    .col-sm-4,
                    .col-sm-8 {
                        padding-right: 0 !important;
                        padding-left: 0 !important;
                    }
                }
            }


            h3 {
                margin-top: 30px;

                color: rgba($color-purple-47408C, 0.59);

                &:first-of-type {
                    margin-top: 0;
                }
            }

            .row {
                margin-top: 14px;

                .link-container {
                    display: inline-flex;
                    align-items: center;
                    margin-top: 20px;
                    height: 56px;

                    a {
                        color: unset;
                        text-decoration: underline;
                    }
                }

                .savePdf-container {
                    @extend .link-container;
                    width: 100%;
                }

                .col-sm-4, .col-sm-8 {
                    padding: 0 11px 0 0;

                    &:last-of-type {
                        padding-right: 0;
                    }
                }

                .p-l-0 {
                    padding-left: 0;
                }

                .p-r-0 {
                    padding-right: 0;
                }
            }

            .documents-grid-container {
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: center;
                margin: auto;
                max-width: 900px;

                .container-wrapper {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    width: 100%;

                    .icons-container {
                        align-items: center;
                        display: inline-flex;
                        margin: 0 10px;
                        cursor: pointer;

                        .svg-icon {
                            width: 15px;
                            height: 15px;
                            margin: 0 6px;

                            svg {
                                width: 15px;
                                height: 15px;
                            }
                        }
                    }

                    .filters-container {
                        display: inline-flex;
                        justify-content: center;
                        width: 100%;
                        margin-bottom: 20px;

                        .filter-item {
                            display: flex;
                            flex-direction: column;
                            text-transform: uppercase;
                            align-items: center;
                            color: rgba($color-purple-darker, 0.63);
                            margin: 0 30px;
                            width: 100px;
                            padding: 4px 0;
                            cursor: pointer;

                            &.selected {
                                border-bottom: 2px solid $color-purple-45408A;
                            }

                            .svg-icon {
                                svg {
                                    max-width: 22px;
                                    height: 22px;
                                    fill: rgba(#000000, 0.26);
                                }
                            }
                        }
                    }
                }

                .document-container {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    font-family: $font-titillium;
                    line-height: 17px;

                    margin: 10px 0;
                    height: 270px;

                    .name {
                        font-size: 12px;
                        color: $color-grey-292929;
                        cursor: pointer;
                    }

                    .description {
                        font-size: 10px;
                        color: rgba($color-grey-292929, 0.37);
                        cursor: pointer;
                    }

                    > .image-container {
                        width: 100%;
                        height: 80%;
                        align-items: center;
                        display: flex;
                        flex-direction: column;
                        margin-bottom: -30px;

                        img {
                            margin: auto;
                            padding: 10px;
                            width: 100%;
                            height: 100%;
                            max-height: 180px;
                            object-fit: contain;
                            cursor: pointer;
                        }

                        .menu {
                            position: relative;
                            width: 100%;
                            top: -40px;

                            button {
                                background-color: #FFF;
                                padding: 5px;
                            }
                        }

                        .menu-content {
                            display: flex;
                            background-color: $color-grey-E5E5E5;
                            flex-direction: column;
                            position: relative;
                            top: -50px;
                            left: -80px;
                            box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
                            font-family: $font-titillium;
                            border-radius: 4px;

                            button {
                                text-transform: unset;
                                width: 100%;

                                &:hover {
                                    background-color: rgba($color-grey-292929, 0.3);
                                    border-radius: 0;

                                    &:last-child {
                                        border-bottom-right-radius: 4px;
                                        border-bottom-left-radius: 4px;
                                    }

                                    &:first-child {
                                        border-top-left-radius: 4px;
                                        border-top-right-radius: 4px;
                                    }
                                }
                            }
                        }
                    }

                }
            }
        }

        .row {
            margin-right: 0;
            margin-left: 0;
            width: 100%;

            &.with-top-margin {
                margin-top: 20px;
            }

            &.two-fields {
                .col-sm-4, .col-sm-6 {
                    padding: 0 5px;
                }
            }
        }
    }

    &__left-container {
        height: 100%;
        width: 260px;
        background-color: $color-cream-FAFAFA;
        box-shadow: 0 3px 6px #00000029;
        z-index: 2;
        display: flex;
        flex-direction: column;

        @media(max-width: 768px) {
            width: 100vw;
        }

        .button-container {
            width: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            padding: 0 3px;
            margin: 0;

            .btn:last-of-type {
                margin-top: 5px;
            }
        }


        &__info-container {
            width: 100%;
            display: inline-flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 20px 0 20px;

            .info-content {
                display: inline-flex;
                align-items: center;

                .svg-icon {
                    margin-right: 15px;
                    width: 15px;
                    height: 15px;

                    svg {
                        fill: rgba(#000000, 0.34);
                        width: 15px;
                        height: 15px;
                    }
                }

                .info {
                    display: flex;
                    flex-direction: column;
                    line-height: 19px;

                    p {
                        font-size: 14px;
                        font-family: $font-titillium;
                    }

                    h4 {
                        font-size: 18px;
                        font-family: $font-titillium-bold;
                    }
                }
            }
        }

        &__centered-info {
            padding: 10px 45px;
            font-family: $font-titillium;
            display: flex;
            flex-direction: column;
            position: relative;
            flex-grow: 1;

            &.pre-inspection-screen {
                .info-item {
                    &:last-of-type {
                        flex-grow: unset !important;
                    }
                }
            }

            .info-item {
                margin-top: 25px;
                color: rgba($color-black-0B0B0B, 0.63);
                line-height: 15px;

                a {
                    color: unset;
                    text-decoration: none;
                }

                .svg-icon {
                    svg {
                        width: 18px;
                        height: 18px;
                        fill: $color-grey-BABABA;
                    }
                }

                .label {
                    margin-top: 11px;
                    color: rgba($color-purple-648FC2, 0.63)
                }

                .status {
                    font-family: $font-titillium-semibold;
                    font-size: 10px;
                    line-height: 15px;

                    &.online {
                        color: $color-green-71AC86;
                    }

                    &.offline {
                        color: $color-red;
                    }
                }

                &:last-of-type {
                    flex-grow: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    margin-bottom: 30px;
                }

                &.smaller-margin {
                    margin-top: 13px;
                }

                &.with-top-margin {
                    margin-top: 50px;
                }
            }

        }

        &__buttons-container {
            display: inline-flex;
            width: 100%;
            padding: 0 3px;
            justify-content: space-between;
            margin-top: 10px;

            &.one-button {
                button {
                    width: 100%;
                }
            }

            button {
                font-family: $font-titillium-semibold;
                width: 49.5%;
            }
        }

        &__video {
            width: 260px;
            height: 150px;
        }

        &__call-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            width: 260px;
            height: 150px;
            background-color: $color-grey-414141;
            padding: 10px;
            flex: 0 0 150px;
            color: #FFFFFF;

            &.incoming-call {
                justify-content: flex-end;

                > .call-info {
                    padding: 20px 0;
                    height: 100%;

                    > h3 {
                        font-family: $font-titillium-semibold;
                        text-transform: uppercase;
                        font-size: 15px;
                        margin-bottom: 5px;
                    }

                    > p {
                        font-family: $font-titillium;
                        font-size: 15px;

                        &.small {
                            font-size: 12px;
                        }
                    }
                }

                .buttons {
                    display: inline-flex;

                    button:nth-of-type(1) {
                        margin-right: 2px;
                    }

                    button:nth-of-type(2) {
                        margin-left: 2px;
                    }
                }
            }

            .svg-icon {
                flex-grow: 1;
                display: flex;
                justify-content: center;
                align-items: center;

                svg {
                    width: 20px;
                    height: 20px;
                    fill: $color-grey-6E6E6E;
                }
            }

            button {
                width: 100%;
            }
        }
    }
}

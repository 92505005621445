/**
 *
 * @Copyright 2024 VOID SOFTWARE, S.A.
 *
 */

 .error-screen {
    h1 {
        font-size: 36px;
        font-family: $font-titillium;
        color: $color-purple;
    }
    
    p {
        font-size: 18px;
        font-family: $font-titillium;
    }

    &__content {
        padding: 20px;
    }
    
    &__buttons-group {
        display: flex;
        justify-content: center;
        gap: 10px;
        margin-top: 20px;
        
        button {
            background-color: $color-purple;
            color: #fff;
            font-size: 22px;
            padding: 10px 20px;
            border-radius: 6px;
            margin: 0;
            text-decoration: none;
            font-family: $font-titillium;
        }
    }
}
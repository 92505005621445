#root {
    height: 100%;
    width: 100%;
}

.app-container, .app-router {
    height: 100%;
    width: 100%;
}
.entry-page {
    width: 100%;
    height: 100%;
    background-image: url('../../images/entry_page_background.png');
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    background-position: center center;

    &__centered {
        background-image: linear-gradient(180deg, rgba(209, 36, 168, 0.54), rgba(11, 52, 119, 0.54));
        width: 418px;
        height: 100%;
        box-shadow: 0px 3px 40px #000000;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        padding: 20px 45px;
        text-align: center;

        &.recover-password {
            justify-content: space-evenly;

            form {
                margin-bottom: 100px;
            }

            h2 {
                color: #ffffff;
                font-size: 18px;
                margin-bottom: 10px;
                font-family: $font-titillium;
                text-align: left;
            }
        }

        .btn--forgot-password {
            font-family: $font-roboto;
            letter-spacing: 0.4px;
            font-size: 12px;
            line-height: 16px;
            opacity: 0.43;
        }

        img {
            width: 100%;
            height: 165px;
        }

        &__fields {
            &__remember-me {
                margin: 16px 0 21px 0;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                color: #ffffff;
                font-size: 12px;
                line-height: 8px;
                width: 100%;

                &.selected {
                    .svg-icon {
                        svg {
                            fill: $color-purple;
                        }
                    }
                }

                .svg-icon {
                    margin-right: 10px;

                    svg {
                        fill: #ffffff;
                    }
                }
            }

            .form-field-container {
                height: 80px;
            }

            &__text-field {
                display: inline-flex;
                align-items: center;
                width: 100%;
                padding: 0 16px;
                border-radius: 4px;
                background-color: #ffffff;
                height: 56px;
                justify-content: space-between;
                color: $color-grey-00000099;

                &:last-child {
                    margin-top: 22px;
                }

                input {
                    font-family: $font-roboto;
                    color: $color-grey-00000099;
                    font-size: 16px;
                    line-height: 21px;
                    letter-spacing: 0.15px;
                    border: none;
                    width: calc(100% - 40px);
                }

                .svg-icon {
                    width: 24px;
                    height: 24px;

                    svg {
                        opacity: 0.5;
                    }
                }
            }
        }
    }
}

.app-page {
    width: 100%;
    height: 100%;
    padding: $app-screen-topbar-height 0 0 0;
}

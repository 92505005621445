/*!
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

.field-container {
    &__label {
        font-family: $font-titillium-semibold;
        line-height: 20px;
        letter-spacing: 0.12px;
    }

    &__text-area {
        font-size: 16px;
        line-height: 21px;
        letter-spacing: 0.15px;
        background-color: $color-text-field-bg;
        display: inline-flex;
        align-items: center;
        width: 100%;
        padding: 15px 16px;
        border-radius: 4px;
        justify-content: space-between;

        &.height-bigger {
            textarea {
                height: 200px;
            }
        }

        &.disabled {
            background-color: rgba($color-grey-F5F5F5, 0.3);

            input {
                background-color: transparent;
            }
        }

        textarea {
            width: 100%;
            font-size: 16px;
            line-height: 21px;
            letter-spacing: 0.15px;
            background-color: transparent;
            border: none;
            resize: none;
        }

        &.small {
            max-height: 120px;

            textarea {
                max-height: 100px;
            }
        }
    }

    &__text-field {
        font-size: 16px;
        line-height: 21px;
        letter-spacing: 0.15px;
        background-color: $color-text-field-bg;
        display: inline-flex;
        align-items: center;
        width: 100%;
        padding: 0 16px;
        border-radius: 4px;
        height: 56px;
        justify-content: space-between;

        .svg-icon {
            svg {
                fill: rgba(#000000, 0.3);
                width: 18px;
                height: 18px;
            }
        }

        .react-datepicker__current-month {
            font-size: 1.5rem !important;
        }

        .react-datepicker__header {
            padding-top: 6px !important;
        }

        .react-datepicker__navigation {
            top: 13px !important;
        }

        .react-datepicker__day-name,
        .react-datepicker__day {
            margin: 0.5rem !important;
            font-size: 1.2rem !important;
        }

        .calendar-container {
            box-shadow: 0 6px 10px #00000029;
        }

        &.with-roboto {
            input {
                font-family: $font-roboto;
                font-size: 13px;
                letter-spacing: 0.12px;
                line-height: 18px;
            }
        }

        &.disabled {
            background-color: rgba($color-grey-F5F5F5, 0.3);

            input {
                background-color: transparent;
            }
        }

        input {
            width: 100%;
            font-size: 16px;
            line-height: 21px;
            letter-spacing: 0.15px;
            background-color: transparent;
            border: none;
        }
    }

    &__select-field {
        font-size: 16px;
        line-height: 21px;
        letter-spacing: 0.15px;
        background-color: $color-text-field-bg;
        display: inline-flex;
        align-items: center;
        width: 100%;
        padding: 0 16px;
        border-radius: 4px;
        height: 56px;
        justify-content: space-between;

        select {
            background-color: transparent;
            border: none;
            width: 100%;
            font-size: 16px;
            line-height: 21px;
            letter-spacing: 0.15px;
        }

        .searchable-select {
            width: 100%;

            &__control {
                background-color: transparent;
                border: none;

                &--is-focused,
                &:hover {
                    border: none;
                    box-shadow: none;
                }
            }

            &__value-container {
                padding: 0;
            }

            &__indicator {
                svg {
                    width: 17px;
                    height: 17px;
                    color: $color-grey-636363;
                }
            }

            &__indicator-separator {
                display: none;
            }

            &__option {
                &--is-focused,
                &:hover {
                    background-color: $color-select-blue;
                }
            }

            &__menu {
                color: $color-white-F7F7F7;
                background-color: $color-grey-6E6E6E;
            }

            &__single-value {
                color: $color-grey-636363;

                &--is-disabled {
                    color: rgba(#000000, 0.3);
                }
            }

            &--is-disabled {
                svg {
                    color: rgba(#000000, 0.3);
                }
            }
        }
    }
}

// sass-lint:disable class-name-format
.toast-container {

    .Toastify {

        &__toast {
            border-radius: 2px;

            &--success {
                background-color: #009900;
            }

            &-body {
                font-family: $font-titillium-bold;
                font-size: 14px;

                p {
                    display: flex;
                    align-items: center;
                    //line-height: 10px;

                    .svg-icon {
                        width: 15px;
                        height: 15px;
                        margin: 0 10px 0 10px;

                        svg {
                            width: 15px;
                            height: 15px;

                            path {
                                fill: #ffffff;
                            }
                        }
                    }
                }
            }
        }
    }
}
// sass-lint:enable class-name-format

.checkbox {
    position: relative;
    margin-top: 30px;

    .button {
        background: transparent;
        border: 0;
        margin-bottom: 0.5rem;
        font-size: 1rem;
        display: flex;
        outline: 0;
        color: #9b9b9b;
        cursor: pointer;
        text-align: left;
        align-items: center;
    }

    .check {
        margin-right: 1rem;

        svg {
            width: 24px;
            height: 24px;
            left: 4px;
            top: 4px;
            position: absolute;
        }

        &::after {
            content: '';
            height: 31px;
            width: 31px;
            background: #d7d0da;
            float: left;
            border: 2px solid #d7d0da;
            border-radius: 3px;
            -webkit-transition: 0.15s all ease-out;
            transition: 0.15s all ease-out;
        }
    }

    .content {
        height: 100%;
        padding: 0;
    }
}

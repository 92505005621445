.app-screen__tabs-container {
    display: inline-flex;
    min-width: 100%;

    &__tabs-items {
        display: inline-flex;

        &__tab-link {
            width: 152px;
            height: 56px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            font-family: $font-titillium-bold;
            font-size: 14px;
            text-transform: uppercase;
            background-color: $color-grey-E5E5E5;
            color: $color-grey-9F9F9F;
            margin-right: 2px;

            &.box-shadow-right {
                box-shadow: inset 10px 0 10px -7px rgba(0,0,0,0.5);
            }

            &.box-shadow-left {
                box-shadow: inset -10px 0px 10px -7px rgba(0,0,0,0.5)
            }

            &.selected {
                color: $color-purple;
                background-color: #ffffff;
                box-shadow: unset;
                z-index: 1;

                &:hover {
                    background-color: unset;
                }
            }

            &.disabled {
                background-color: $color-grey-E5E5E5;
                opacity: 0.5;

                &:hover {
                    cursor: default;
                    background-color: $color-grey-E5E5E5;
                    opacity: 0.5;
                }
            }

            &:hover {
                background-color: #D3D3D3;
            }
        }
    }

    &__search {
        display: inline-flex;
        align-items: center;
        padding: 16px;
        width: 100%;

        &__search-field {
            display: inline-flex;
            width: 100%;
            height: 100%;
            max-width: 400px;
            border-bottom: 1px solid rgba(#000000, 0.35);

            form {
                width: 100%;
                max-width: 100%;
            }

            input {
                display: block;
                padding: 0 10px;
                width: 100%;
                height: 100%;
                max-width: 100%;
                font-size: 16px;
                line-height: 21px;
                background-color: inherit;
            }
        }

        .svg-icon {
            width: 18px;
            height: 18px;
            margin-left: 10px;

            svg {
                fill: rgba(#000000, 0.35);
            }
        }
    }

    &__button-container {
        align-self: center;
        margin-right: 10px;
        font-family: $font-roboto;
        color: #ffffff;
        flex-grow: 1;
        display: inline-flex;
        justify-content: flex-end;

        @media (max-width: 768px) {
            flex-flow: column;
        }

        .btn {
            box-shadow: 0 1px 3px #00000033;
            text-transform: unset;
            color: #ffffff;

            @media (max-width: 768px) {
                width: 100%;
                margin-bottom: 16px;
            }

            &--just-icon {
                padding: 0 10px;
                display: inline-flex;
                align-items: center;

                .svg-icon {
                    display: inline-flex;
                    align-items: center;

                    span {
                        display: inline-flex;
                        align-items: center;
                    }
                }
            }

            &.second-button {
                margin-right: 10px;
                .svg-icon {
                    width: 15px;
                    height: 15px;

                    svg {
                        width: 15px;
                        height: 15px;
                        fill: #FFFFFF;
                    }
                }
            }

            &.resend-invites {
                margin-right: 10px;
                .svg-icon {
                    width: 22px;
                    height: 16px;

                    svg {
                        width: 22px;
                        height: 16px;
                        fill: #FFFFFF;
                    }
                }
            }

            .svg-icon {
                width: 15px;
                height: 15px;

                svg {
                    width: 22px;
                    height: 16px;
                    fill: #FFFFFF;
                }
            }
        }
    }

    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        &__tabs-items {
            width: 100vw;
            margin-bottom: 20px;

            &__tab-link {
                width: 100px;
                height: 40px;
                font-size: 10px;
                margin-right: 1px;
            }
        }

        &__button-container {
            align-self: flex-start;
            margin-left: 10px;
        }
    }
}

/**
 *
 * @Copyright 2024 VOID SOFTWARE, S.A.
 *
 */

.damage-opacity-input {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
    padding: 10px;

    .opacity-value {
        display: flex;
        gap: 10px;
        align-items: center;

        label {
            font-family: $font-titillium;
            width: max-content;
        }

        span {
            background-color: #ababab;
            color: white;
            padding: 3px 8px;
            border-radius: 6px;
            pointer-events: none;
            text-align: center;
            min-width: 56px;
        }
    }

    .slider-opacity {
        -webkit-appearance: none;
        appearance: none;
        min-width: 60%;
        height: 5px;
        background: #ADD8E6;
        outline: none;
        transition: opacity 0.2s;
        border-radius: 8px;
    }

    .slider-opacity:hover {
        opacity: 1;
    }

    .slider-opacity::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 15px;
        height: 15px;
        background: #ABABAB;
        cursor: pointer;
        border-radius: 50%;
        transition: background 0.2s;
    }

    .slider-opacity::-webkit-slider-thumb:hover {
        background: #7B7B7B;
    }
}

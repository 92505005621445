html, body {
    height: 100vh;
    margin: 0;
    padding: 0;
}

* {
    box-sizing: border-box;
}

body {
    font-family: $font-titillium;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
}

button {
    padding: 0;
    background: transparent;
    border: none;
    cursor: pointer;

    &:focus {
        outline: none;
    }
}

input {
    border: none;

    &:focus {
        outline-offset: unset;
        outline: none;
    }
}

.form-text-field {
    border-radius: 4px;
}

// PAGING
.paging {
    display: flex;
    justify-content: flex-end;
    padding: 10px 0;
    font-family: $font-titillium;
    font-size: 14px;

    button {
        justify-content: center;
        align-items: center;
        min-width: 34px;
        height: 34px;
        margin: 0;
        text-align: center;
        color: rgba($color-purple, 0.4);

        .svg-icon {
            width: 10px;
            height: 10px;

            svg {
                width: 10px;
                height: 10px;
                fill: rgba($color-purple, 0.4);
            }
        }

        &:hover {
            color: $color-purple;
        }
    }


    .pages {
        display: flex;

        .page,
        .more {
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: 34px;
            height: 34px;
            margin: 0;
            text-align: center;
            color: rgba($color-purple, 0.2);
        }

        .page {
            color: $color-purple;
        }
    }
}

// ERRORS
.field-error {
    font-family: $font-titillium-bold;
    color: red;
}

.status-dot {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    justify-content: center;

    &.black-circle {
        background-color: $color-grey-414141;
    }

    &.red-circle {
        background-color: $color-red;
    }

    &.green-circle {
        background-color: $color-green;
    }

    &.yellow-circle {
        background-color: $color-yellow;
    }

    &.orange-circle {
        background-color: $color-orange;
    }
}

.app-screen__video {
    &.smaller {
        width: 260px;
        height: 150px;
        position: relative;
        background-color: #000;

        .video-container {
            position: relative;
            width: 260px;
            height: 150px;
        }
    }

    &.bigger {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        z-index: 2;
        position: absolute;
        top: 0;
        left: 0;
        margin: auto;
        background-color: rgba(#000, 0.8);

        .video-container {
            background-color: #000;
            position: relative;
            width: 1000px;
            height: 600px;

            .btn {
                width: unset;
            }
        }
    }

    .calling-container {
        position: absolute;
        padding: 20px;
        top: 0;
        left: 0;
        color: #FFFFFF;
        text-align: center;
        font-size: 12px;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        z-index: 3;
        background-color: $color-grey-414141;

        .buttons {
            display: inline-flex;

            button:nth-of-type(1) {
                margin-right: 2px;
            }

            button:nth-of-type(2) {
                margin-left: 2px;
            }
        }

        .calling-info {
            height: 100%;

            > h3 {
                font-family: $font-titillium-semibold;
                text-transform: uppercase;
                font-size: 15px;
                margin-bottom: 5px;
            }

            > p {
                font-family: $font-titillium;
                font-size: 15px;

                &.small {
                    font-size: 12px;
                }
            }
        }

    }

    .video {
        width: 100%;
        max-width: 500px;
        height: 100%;
        left: 50%;
        object-fit: cover;
        transform: translateX(-50%);
        position: relative;

        &.hidden {
            display: none;
        }
    }

    .canvas {
        width: 100%;
        height: 100%;
        display: none;
    }

    .top-container {
        position: absolute;
        top: 5px;
        right: 5px;
        background-color: $color-grey-1F1F1F;
        border-radius: 4px;
        z-index: 2;
        box-shadow: 0 1px 3px #00000033;
        width: 30px;
        height: 30px;
        cursor: pointer;
        display: inline-flex;
        justify-content: center;
        align-items: center;

        .svg-icon {
            width: 15px;
            height: 15px;
            opacity: 0.5;

            svg {
                fill: #FFFFFF;
                width: 15px;
                height: 15px;
            }
        }
    }


    .controls-container {
        position: absolute;
        left: 0;
        bottom: 0;
        color: white;
        display: inline-flex;
        justify-content: center;
        width: 100%;
        padding: 7px 0;
        z-index: 2;

        .btn--with-icon {
            .svg-icon {
                margin-right: 11px;
            }
        }

        .btn {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 30px;
            height: 30px;
            line-height: 1px;

            &:nth-of-type(2) {
                margin: 0 5px;
            }

            .svg-icon {
                width: 15px !important;
                height: 15px !important;
                opacity: 0.5;

                svg {
                    fill: #FFFFFF;
                    width: 15px;
                    height: 15px;
                }
            }
        }
    }
}
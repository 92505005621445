.app-screen__table {
    padding: 24px 42px;
    height: 100%;
    overflow-x: auto;
    
    @media (max-width: 768px) {
        margin: 1.25% 2.2%;
        padding: 0;
    }

    &.documents-table {
        padding: 0 !important;
    }


    &__content {
        position: relative;
        display: flex;
        flex-direction: column;
        margin: auto;
        max-width: 1200px;

        @media (min-width: 768px) {
            align-items: center;
        }

        .container-wrapper {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;

            .icons-container {
                align-items: center;
                display: inline-flex;
                margin: 0 10px;
                cursor: pointer;
    
                .svg-icon {
                    width: 15px;
                    height: 15px;
                    margin: 0 6px;
    
                    svg {
                        width: 15px;
                        height: 15px;
                    }
                }
            }

            .filters-container {
                display: inline-flex;
                justify-content: center;
                width: 100%;
                margin-bottom: 20px;
    
                .filter-item {
                    display: flex;
                    flex-direction: column;
                    text-transform: uppercase;
                    align-items: center;
                    color: rgba($color-purple-darker, 0.63);
                    margin: 0 30px;
                    width: 100px;
                    padding: 4px 0;
                    cursor: pointer;
    
                    &.selected {
                        border-bottom: 2px solid $color-purple-45408A;
                    }
    
                    .svg-icon {
                        svg {
                            max-width: 22px;
                            height: 22px;
                            fill: rgba(#000000, 0.26);
                        }
                    }
                }
            } 
        }

        .table {
            width: 100%;
            border-collapse: collapse;

            td .actions-cell {
                width: 30px;
                justify-content: flex-end;
            }

            th,
            td {
                height: 42px;

                > div {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    width: 100%;
                    height: 100%;

                    > .svg-icon {
                        width: 15px;
                        height: 15px;

                        svg {
                            width: 15px;
                            height: 15px;
                            fill: #000000;
                            opacity: 0.14;
                        }
                    }
                }

                .actions-cell {
                    justify-content: flex-end;
                    position: relative;
                    width: 30px;

                    .svg-icon {
                        height: 18px;
                        width: 18px;

                        svg {
                            height: 18px;
                            width: 18px;
                            fill: rgba(#000000, 0.7);
                        }
                    }

                    .actions-drop-down {
                        font-family: $font-titillium;
                        border-radius: 4px;
                        background-color: $color-grey-E5E5E5;
                        position: absolute;
                        box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
                        z-index: 1;
                        top: 20px;
                        right: 10px;

                        button {
                            text-transform: unset;
                            width: 100%;

                            &:hover {
                                background-color: rgba($color-grey-292929, 0.3);
                                border-radius: 0;

                                &:last-child {
                                    border-bottom-right-radius: 4px;
                                    border-bottom-left-radius: 4px;
                                }

                                &:first-child {
                                    border-top-left-radius: 4px;
                                    border-top-right-radius: 4px;
                                }
                            }
                        }
                    }
                }

                .status-cell {
                    justify-content: center;

                    span {
                        width: 18px;
                        height: 18px;
                        border-radius: 50%;
                    }

                    .red-circle {
                        background-color: $color-red;
                    }

                    .black-circle {
                        background-color: $color-grey-414141;
                    }

                    .green-circle {
                        background-color: $color-green;
                    }

                    .yellow-circle {
                        background-color: $color-yellow;
                    }

                    .orange-circle {
                        background-color: $color-orange;
                    }
                }
            }

            thead {
                th {
                    text-align: left;
                    vertical-align: center;
                    font-family: $font-titillium-semibold;
                    color: $color-purple-darker-transparent;

                    div {
                        padding: 4px 10px;

                        &.sortable {
                            cursor: pointer;
                        }

                        &:hover {
                            color: $color-purple-darker;
                        }

                        > div {
                            .svg-icon {
                                width: 15px;
                                height: 15px;
                                display: inline-flex;
                                align-items: center;
    
                                span {
                                    display: inline-flex;
    
                                    svg {
                                        width: 9px;
                                        height: 9px;
                                        fill: #000000;
                                        opacity: 0.14;
                                    }
                                }
                            }
                        }

                                

                    }
                }
            }

            tbody {
                border-radius: 1px;

                .not-seen {
                    font-family: $font-titillium-bold;
                    border-left: 2px solid $color-purple;
                }

                tr {
                    border-bottom: 4px solid #fff;
                    cursor: pointer;
                    font-family: $font-titillium;
                    font-size: 16px;
                    line-height: 17px;
                    text-align: left;
                    height: 48px;
                    color: $color-grey-292929;

                    .icon-cell {
                        text-align: center;
                        width: 35px;

                        .svg-icon {
                            width: 18px;
                            height: 18px;

                            svg {
                                width: 18px;
                                height: 18px;
                                fill: rgba(#000000, 0.26)
                            }
                        }
                    }

                    &:hover {
                        td {
                            background-color: rgba($color-grey-292929, 0.1);
                        }
                    }

                    &:nth-child(even) {
                        background-color: rgba($color-grey-E5E5E5, 0.12);
                    }

                    &:nth-child(odd) {
                        background-color: rgba($color-grey-E5E5E5, 0.32);
                    }

                    td {
                        padding: 4px 10px;
                        max-width: 400px;
                        word-break: break-all;

                        @media (max-width: 768px) {
                            word-break: normal;
                        }
                    }

                    .actions-cell {
                        margin-right: 10px;
                        width: 45px;
                    }

                    .bold-text {
                        font-family: $font-titillium-bold;
                    }

                    .document-name-cell {
                        width: 64%;
                    }

                    .date-cell {
                        max-width: 180px !important;
                    }
                }

                &.no-data {
                    tr {
                        td {
                            text-align: center;
                        }
                    }
                }
            }

        }

        .paging {
            align-self: flex-end;
        }
    }

    .bigger {
        max-width: 1800px;
    }
}
